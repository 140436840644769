import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron,
    Table
} from 'reactstrap';

import {  Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route,useLocation} from 'react-router-dom';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUniversity, faUsers, faGlobe } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Pagination from './Pagination.js'


library.add(faUniversity, faUsers);

class WtbDirectoryAccountsChecked extends React.Component {
  constructor(props) {
    super(props);
    this.json_fetched = false
    this.state = {
      accounts: []
    }
  }

  getdata(curpage) {
        
    axios.get("https://static.welchebank.eu/directory/accounts/checked/"+curpage+".json")
      .then(response => {
        this.setState({
          accounts: response.data.accounts,
          pages: response.data.pages
        });
      })
  }  
  
  render() {    
    const { accounts = [], pages=0 } = this.state;
    var url
    var curpage    
    url = this.props.location.pathname
    if (url=="/directory/accounts/checked")
      curpage = "1"
    else
      curpage=url.replace("/directory/accounts/checked/", "");    
    
      if (!this.json_fetched)
        this.getdata(curpage)
        this.json_fetched = true

        return (                    
          <div className="mt-5">          
          <Container>                             
          <Row>
            <Col sm="12" xs="12" md={{ size: 6, offset: 3 }}>              
            <Row>
                          <Col xs="5">
                        <FontAwesomeIcon icon={faGlobe} size="10x" color="#2780E3"/>
                      </Col>
                      <Col xs="7">
                      <h4 class="pt-3">All accounts</h4>
                      <p class="text-muted">List of all checked accounts.</p>              
                      </Col>
                      </Row>       
              </Col>
              </Row>              
              <Row className="mt-1"> 
                <Col sm="12" xs="12" md={{ size: 10, offset: 1 }}>
                  <Card className="card border-primary rounded mt-2" outline color="primary"> 
                    <CardHeader className="card-header bg-primary text-white">                
                      <p class="mt-0 mb-0">Accounts</p>
                    </CardHeader>
                    <CardBody className="card-body">  
                        <Table className="table table-bordered table-striped table-sm">
                          <thead>
                            <tr>
                              <th>Account Number</th>
                            </tr>
                          </thead>
                          <tbody>
                          {accounts.length ? 
                            accounts.map(account => (
                              <tr>
                                <td><a href={"/account/" + account.account_no}>{account.account_no}</a></td>
                              </tr>
                            ))
                            : 
                            (<tr>
                              <td>-</td>
                            </tr>)
                          }
                          </tbody>
                        </Table>                                                 
                        <Pagination totalRecords={8} pageLimit={5} pageNeighbours={1} link="/directory/accounts/checked" curpage={curpage} />
                    </CardBody>
                  </Card>
                </Col>                 
              </Row>              
            </Container>    
            </div>
          );
    }
  }

export default WtbDirectoryAccountsChecked;
