import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
//  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Row,
  Container
} from 'reactstrap';

import { NavLink } from 'react-router-dom';


import { Link } from 'react-router-dom';

const WtbNav = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (    
    <div class="navbar navbar-expand-lg navbar-dark bg-primary navbar-static-top">  
    <Container>
    <Navbar className="navbar navbar-expand-lg navbar-dark bg-primary navbar-static-top">
      <NavbarBrand tag={Link} to="/">welchebank.eu</NavbarBrand>
        <NavbarToggler onClick={toggle} />      
        <Collapse isOpen={isOpen} navbar class="collapse navbar-collapse" id="navbarColor01">
          <Nav className="navbar-nav mr-auto" navbar>
            <NavItem className="nav-item"><NavLink exact tag={Link} activeClassName="active" className="nav-link" to="/">Home</NavLink></NavItem>                          
            <NavItem className="nav-item"><NavLink tag={Link} activeClassName="active"  className="nav-link" to="/directory">Directory</NavLink></NavItem>                
            <NavItem className="nav-item"><NavLink tag={Link} activeClassName="active" className="nav-link" to="/about/">About</NavLink></NavItem>                
          </Nav>
      </Collapse>
    </Navbar>
    </Container>
  </div>
  );
}

export default WtbNav;
