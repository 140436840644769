import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron
} from 'reactstrap';

import {  Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route,useLocation} from 'react-router-dom';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUniversity, faUsers } from '@fortawesome/free-solid-svg-icons';

library.add(faUniversity, faUsers);

class WtbDirectory extends React.Component {
    constructor() {      
      super();             
    }
 
    render() {      
        return (           
          <div className="mt-5">
          <Container>                             
          <Row>
            <Col sm="12" xs="12" md={{ size: 6, offset: 3 }}>              
            <Row>
                          <Col xs="4">
                        <div class="float-left display-4 mr-4">Go!</div>
                      </Col>
                      <Col xs="8">
                      <h4 class="pt-3">Chose the category.</h4>
                      <p class="text-muted">You can browse following categories.</p>              
                      </Col>
                      </Row>       
              </Col>
              </Row>              
              <Row className="mt-1"> 
                <Col sm="12" xs="12" md={{ size: 4, offset: 1 }}>
                  <Card className="mt-2 card border-primary rounded" outline color="primary"> 
                    <CardHeader className="card-header bg-primary text-white">                
                      <p class="mt-0 mb-0">Banks</p>
                    </CardHeader>
                    <CardBody className="card-body">  
                    <p class="text-center">
                      <Link to="/directory/banks"><FontAwesomeIcon icon='university' size="10x" color="#2780E3"/></Link>
                      </p>
                        List of banks available in our database.                            
                    </CardBody>
                  </Card>
                </Col>             
                <Col sm="12" xs="12" md={{ size: 4, offset: 1 }}>
                  <Card className="mt-2 card border-primary rounded"outline color="primary"> 
                    <CardHeader className="card-header bg-primary text-white">                
                      <p class="mt-0 mb-0">Accounts</p>
                    </CardHeader>
                    <CardBody className="card-body">  
                    <p class="text-center">
                    <Link to="/directory/accounts"><FontAwesomeIcon icon='users' size="10x" color="#2780E3"/></Link>
                      </p>
                        IBAN numbers checked with welchebank.eu
                    </CardBody>
                  </Card>
                </Col>             

              </Row>
            </Container>    
            </div>
          );
    }
  }

export default WtbDirectory;