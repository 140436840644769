import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron,
    Table
} from 'reactstrap';

import {  Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route,useLocation} from 'react-router-dom';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUniversity, faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


library.add(faUniversity, faUsers);

class WtbDirectoryBank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    }
  }

  getData (bank_id) {
    var a = fetch("https://static.welchebank.eu/banks/"+bank_id+".json")
    .then(response => {
      if (!response.ok) {
        //this.handleResponseError(response);
        this.setState({bankData: "N/A", 
          bankName: null,
          plz: null,
          city: null,
          shortName: null,
          bic: null,
          bankAddress: null})
      }
      return response.json();
    })
    .then(json => {
      this.setState({bankData: json, 
        bankName: json.bank_name,
        plz: json.plz,
        city: json.city,
        bank_id: bank_id, 
        country: "Germany",
        shortName: json.short_name,
        bic: json.bic,
        bankAddress: json.plz+" "+json.city})
      return json;        
    });
    return a;
  }

  componentDidMount() {
    var url=this.props.location.pathname
    var bank_id=url.replace("/directory/bank/", "");  
    this.getData(bank_id)
    
    /*axios.get("https://static.welchebank.eu/banks/"+this.af+".json")
      .then(response => {        
        this.setState({        
          data: response.data
        });
      }) */
  }

  render() {
    const { data = [] } = this.state;
        return (                    
          <div className="mt-5">
          <Container>              
          <Row>
            <Col sm="12" xs="12" md={{ size: 6, offset: 3 }}>              
            <Row>
                          <Col xs="5">
                        <FontAwesomeIcon icon='university' size="10x" color="#2780E3"/>
                      </Col>
                      <Col xs="7">
                      <h4 class="pt-3">Bank details</h4>
                      <p class="text-muted">{this.state.shortName}</p>              
                      </Col>
                      </Row>       
              </Col>
              </Row>     
          <Row>
            <Col sm="12" xs="12" md={{ size: 8, offset: 2 }}>              
            <div class="text-right pb-1">
              <a href="/directory/banks"><button type="button" class="btn btn-outline-primary rounded">Bank List</button></a>
              </div>
              <Card className="card border-primary rounded" outline color="primary"> 
              <CardHeader className="card-header bg-primary text-white">
                <h2 class="mt-0 mb-4">{this.state.shortName}</h2>
               <p class="mt-0 mb-0">Bank details</p>
                </CardHeader>
                        <CardBody className="card-body">                                            
                          <table class="table table-bordered table-striped table-sm">
                            <tbody> 
                              <tr>                            
                                <th scope="row">Country:</th>
                                <td>{this.state.country}</td>
                              </tr>                            
                              <tr>
                                <th scope="row">Bank Code (BLZ):</th>
                                <td>{this.state.bank_id}</td>
                                </tr>                                
                                <tr>
                                <th scope="row">Bank Name:</th>
                                <td>{this.state.bankName}</td>
                                </tr>
                                <tr>
                                <th scope="row">Bank Short Name:</th>
                                <td>{this.state.shortName}</td>
                                </tr>
                                <tr>
                                <th scope="row">Bank Address:</th>
                                <td>{this.state.bankAddress}</td>
                                </tr>
                                <tr>
                                <th scope="row">BIC:</th>
                                <td>{this.state.bic}</td>
                                </tr>                  
                              </tbody>
                              </table>  
                        </CardBody>
                      </Card>         
              </Col>
              </Row>              
            </Container>    
            </div>
          );
    }
  }

export default WtbDirectoryBank;