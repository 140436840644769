import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron,
    Table
} from 'reactstrap';

import {  Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route,useLocation} from 'react-router-dom';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUniversity, faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


library.add(faUniversity, faUsers);

class WtbDirectoryBanks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      todos: []
    }
  }

  componentDidMount() {
    axios.get("https://static.welchebank.eu/directory/banks.json")
      .then(response => {
        this.setState({
          todos: response.data
        });
      })
  }


  render() {
    const { todos = [] } = this.state;
        return (                    
          <div className="mt-5">          
          <Container>                             
          <Row>
            <Col sm="12" xs="12" md={{ size: 6, offset: 3 }}>              
            <Row>
                          <Col xs="5">
                        <FontAwesomeIcon icon='university' size="10x" color="#2780E3"/>
                      </Col>
                      <Col xs="7">
                      <h4 class="pt-3">Banks</h4>
                      <p class="text-muted">List of all banks.</p>              
                      </Col>
                      </Row>       
              </Col>
              </Row>              
              <Row className="mt-1"> 
                <Col sm="12" xs="12" md={{ size: 10, offset: 1 }}>
                  <Card className="card border-primary rounded mt-2" outline color="primary"> 
                    <CardHeader className="card-header bg-primary text-white">                
                      <p class="mt-0 mb-0">Banks</p>
                    </CardHeader>
                    <CardBody className="card-body">  
                        <Table className="table table-bordered table-striped table-sm">
                          <thead>
                            <tr>
                              <th>Bank Code (BLZ)</th>
                              <th>Bank Name</th>
                            </tr>
                          </thead>
                          <tbody>
                          {todos.length ? 
                            todos.map(todo => (
                              <tr>
                                <td><a href={"/directory/bank/" + todo.bank_no}>{todo.bank_no}</a></td>
                                <td>{todo.bank_name}</td>
                              </tr>
                            ))
                            : 
                            (<tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>)
                          }
                          </tbody>
                        </Table>                                                 
                    </CardBody>
                  </Card>
                </Col>                 
              </Row>
            </Container>    
            </div>
          );
    }
  }

export default WtbDirectoryBanks;