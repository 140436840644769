import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron,
    Footer
} from 'reactstrap';

const WtbFooter = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (        
          <footer class="footer fixed-bottom">        
        <div class="text-center fixed-bottom pb-2">
        <div class="sm-12"><a href="/">Home</a> | <a href="/directory">Directory</a> | <a href="/about">About</a></div>
          <div class="sm-12">
<a href="https://welchebank.eu">welchebank.eu</a> © 2020
</div>
</div>
        </footer>    
  );
}

export default WtbFooter;