import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Button } from 'reactstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bootstrap.min.css';
import WtbNav from './WtbNav.js'
import WtbSearchForm from './WtbSearchForm.js'
import WtbFooter from './WtbFooter.js'
import WtbAccountInfo from './WtbAccountInfo.js'
import WtbAbout from './WtbAbout.js'
import WtbJumbo from './WtbJumbo.js'
import WtbDirectory from './WtbDirectory.js'
import WtbDirectoryBanks from './WtbDirectoryBanks.js'
import WtbDirectoryBank from './WtbDirectoryBank.js'
import WtbDirectoryAccounts from './WtbDirectoryAccounts.js'
import WtbDirectoryAccountsChecked from './WtbDirectoryAccountsChecked.js'
import { Container} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

// https://www.bundesbank.de/en/tasks/payment-systems/services/bank-sort-codes/download-bank-sort-codes-626218


const header = new Headers();
header.append('Access-Control-Allow-Origin', '*');

ReactDOM.render(
  <Router>
 <WtbNav/>      
        <Route path='/' exact component={WtbSearchForm  }/>        
        <Route path='/' exact component={WtbJumbo  }/>        
        <Route path='/account' component={WtbAccountInfo}/>         
        <Route path='/directory' exact component={WtbDirectory}/> 
        <Route path='/directory/banks' exact component={WtbDirectoryBanks}/> 
        <Route path='/directory/accounts' exact component={WtbDirectoryAccounts}/>     
        <Route path='/directory/accounts/checked' component={WtbDirectoryAccountsChecked}/>             
        <Route path='/directory/bank/' component={WtbDirectoryBank}/> 
        <Route path='/about' component={WtbAbout}/>     
        <div class="mt-5"><Container>Example:  <a href="/account/DE08100208900014926275">DE 08 1002 0890 0014 9262 75</a></Container>       </div>
    <WtbFooter/>  
  </Router>,
  document.getElementById('root')
);
//<Button color="danger">Danger!</Button>

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
