import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  FormErrors,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
  FormFeedback,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron
} from 'reactstrap';

import {  Redirect } from "react-router-dom";

import { BrowserRouter, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AvForm, AvField } from 'availity-reactstrap-validation';


class WtbSearchForm extends React.Component {
    constructor() {
      super();
      //this.handleSubmit = this.handleSubmit.bind(this);
    }  

    state = {
        redirect: false, 
        account: null,
        formValid:null
      }

      validate = (iban, redirect = false) => {          
        var v_formValid = iban.match(/^[0-9]{20}$/i);
        this.setState({          
            formValid: v_formValid ? true : false,
            formInvalid: false
            }) 
        if (redirect)
        this.setState({                      
            formInvalid: v_formValid ? false : true
            }) 
        return v_formValid                 
    }

      setRedirect = (event) => {  
        event.preventDefault();          
        event.target.IBAN.value=event.target.IBAN.value.replace(RegExp(' ','g'),'')
        if(event.target.IBAN.value.substring(0,2)=='DE')
          event.target.IBAN.value=event.target.IBAN.value.substring(2)
        if (this.validate(event.target.IBAN.value, true)) {            
            this.setState({
                redirect: true,
                account: event.target.IBAN.value})
            }
            
      }
      renderRedirect = () => {
        if (this.state.redirect) {         
            return <Redirect to= {{pathname: '/account/DE'+this.state.account}}  />
        }
      }
  
    render() {
        return ( 
            <Container>                 
                <Row >
                  <Col sm="12" xs="12" md={{ size: 6, offset: 3 }}>              
                    <div class="clearfix" class="mt-5">            
                      <Row>
                          <Col xs="4">
                        <div class="float-left display-4 mr-4">Go!</div>
                      </Col>
                      <Col xs="8">
                      <h4 class="pt-3">Please enter bank account.</h4>
                      <p class="text-muted">We will provide you all the details afterwards.</p>              
                      </Col>
                      </Row>                          
                    <div class="mt-5">           
                    <Card className="card border-primary rounded" outline color="primary">
                    {this.renderRedirect()}
                    <Form onSubmit={this.setRedirect}>
                        <CardHeader className="card-header bg-primary text-white">Check IBAN account </CardHeader>
                        <CardBody className="card-body">                  
                          <p class="card-text">Enter the 20-digit German IBAN bank account number:</p>
                          <InputGroup className="input-prepend input-group">                                              
                          <InputGroupAddon addonType="prepend">
                        <InputGroupText>DE</InputGroupText>
                        </InputGroupAddon>
                        <Input  id="IBAN" size="40" type="text" placeholder="IBAN bank account number" required valid={ this.state.formValid} invalid={ this.state.formInvalid} onChange={(e) => this.validate(`${e.target.value}`)}/>                
                        <FormFeedback valid>
                        Looks like this IBAN is correct! Press the "Check!" button to perform full check.
                        </FormFeedback>
                        <FormFeedback invalid>
                        Uh oh! Looks like there is an issue with this IBAN number. Please provide only 20 digits. No spaces.
                        </FormFeedback>
                    </InputGroup>
                    <div class="text-center">
                    <Button className ="mt-3 btn-warning rounded" center-block> Check!</Button>
                    </div>
                        </CardBody>
                        </Form>

                      </Card>            
                    </div>
                    </div>
                  </Col>                   
                </Row>             
            </Container>                
          );
    }
  }

export default WtbSearchForm;