import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron
} from 'reactstrap';

import {  Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route,useLocation} from 'react-router-dom';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
//import { DiscussionEmbed } from 'disqus-react';
import { FacebookProvider, Comments } from 'react-facebook';



library.add(faCheck, faTimes);

class WtbAccountInfo extends React.Component {
    constructor() {      
      super();             
      this.state = {
        isFetching: false,
        bankData: null
    };
    }

    state = {
      bankData: null,
      isLoading: null,
    };


    getData (bank_id, account_id) {
      var a = fetch("https://static.welchebank.eu/banks/"+bank_id+".json")
      .then(response => {
        if (!response.ok) {
          //this.handleResponseError(response);
          this.setState({bankData: "N/A", 
            bankName: null,
            plz: null,
            city: null,
            shortName: null,
            bic: null,
            bankAddress: null})
        }
        return response.json();
      })
      .then(json => {
        this.setState({bankData: json, 
          bankName: json.bank_name,
          plz: json.plz,
          city: json.city,
          shortName: json.short_name,
          bic: json.bic,
          bankAddress: json.plz+" "+json.city})
        return json;        
      });
      var a = fetch("https://static.welchebank.eu/account/comments/"+account_id+".json")
      .then(response => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then(json => {
        this.setState({comments: json.comments})
        return json;        
      });
      return a;
    }

    componentDidMount() {
    // this.getData()
    }

    calcAcc(url) {      
      //console.log(url)
      if (url === "/account")
        return;
      this.url=url
      this.af=url.replace("/account/", "");
      this.account_full=this.af
      this.account_full_formatted= this.af.substring(0,2)+' '+this.af.substring(2,4)+' '+this.af.substring(4,8)+' '+this.af.substring(8,12)+' '+this.af.substring(12,16)+' '+this.af.substring(16,20)+' '+this.af.substring(20,22)
      this.notformatted_no_country = this.af.substring(2,22)
      this.country_code=this.af.substring(0,2)
      this.country="Germany"
      this.crc=this.af.substring(2,4)
      this.state.bank_id=this.af.substring(4,12)
      this.bank_id=this.af.substring(4,12)
      this.account_no=this.af.substring(12,22)
      this.bban=this.af.substring(4,22)


      var ibanLen = { 
        NO:15, BE:16, DK:18, FI:18, FO:18, GL:18, NL:18, MK:19,
        SI:19, AT:20, BA:20, EE:20, KZ:20, LT:20, LU:20, CR:21,
        CH:21, HR:21, LI:21, LV:21, BG:22, BH:22, DE:22, GB:22,
        GE:22, IE:22, ME:22, RS:22, AE:23, GI:23, IL:23, AD:24,
        CZ:24, ES:24, MD:24, PK:24, RO:24, SA:24, SE:24, SK:24,
        VG:24, TN:24, PT:25, IS:26, TR:26, FR:27, GR:27, IT:27,
        MC:27, MR:27, SM:27, AL:28, AZ:28, CY:28, DO:28, GT:28,
        HU:28, LB:28, PL:28, BR:29, PS:29, KW:30, MU:30, MT:31
      }
       
        var iban
        var iban_org = this.af
        iban_org = iban_org.replace(/\s/g, '')
        var len = iban_org.length

        function pad(num, size) {
          var s = "000000000" + num;
          return s.substr(s.length-size);
      }

        for (var j=0;j<100;j++) {
          console.log('i: '+j)
          var tmp_crc=pad(j,2)
            iban = iban_org.substr(4) + iban_org.substr(0,2)+tmp_crc
            console.log('iban: '+iban)
            for (var s='', i=0; i<len; i+=1) 
              s+=parseInt(iban.charAt(i),36)
            for (var m=s.substr(0,15)%97, s=s.substr(15); s; s=s.substr(13)) {
                m=(m+s.substr(0,13))%97   
              }
              console.log('m: '+m)
            if (m==1) {
              console.log('m: '+m)
              this.crc_calc=tmp_crc
              console.log('break')
              break;
            }
            if (j > 100 )
              break;              
          }        



      if (this.state.bankData === null)
        this.getData(this.bank_id, this.account_full)
    }    
 
    render() {
      this.calcAcc(this.props.location.pathname);
        return (           
          <div className="mt-5">
          <Container>                 
          <Row>
            <Col sm="12" xs="12" md={{ size: 8, offset: 2 }}>    
                        <div class="text-center mb-5"><FontAwesomeIcon icon={(this.crc === this.crc_calc) ? 'check':'times'} size="10x" color={(this.crc === this.crc_calc) ? '#3FB618':'#FF0039'}/></div>
              <Card className="card border-primary rounded" outline color="primary"> 
              <CardHeader className="card-header bg-primary text-white">
                <h2 class="mt-0 mb-4">Details of IBAN account number:</h2>
               <p class="mt-0 mb-0">{this.country_code} {this.notformatted_no_country}</p>
                </CardHeader>
                        <CardBody className="card-body">                                            
                          <table class="table table-bordered table-striped table-sm">
                            <tbody>
                              <tr>
                                <th scope="row">Full IBAN number:</th>
                                <td>{this.account_full_formatted}</td>
                              </tr>
                              <tr>
                                <th scope="row">Country ISO code:</th>
                                <td>{this.country_code}</td>
                              </tr>
                              <tr>
                                <th scope="row">Country:</th>
                                <td>{this.country}</td>
                              </tr>
                              <tr>
                                <th scope="row">Check Digits (CRC):</th>                                
                                <td>{this.crc} <FontAwesomeIcon icon={(this.crc === this.crc_calc) ? 'check':'times'} size="1x" color={(this.crc === this.crc_calc) ? '#3FB618':'#FF0039'}/> 
                                {(this.crc !== this.crc_calc) ? ' (Correct value is: '+this.crc_calc+")" : ''}
                                </td>
                              </tr>    
                              <tr>
                                <th scope="row">BBAN</th>
                                <td>{this.bban}</td>
                              </tr>  
                              <tr>
                                <th scope="row">Bank Code (BLZ):</th>
                                <td><Link to={"/directory/bank/"+this.bank_id}>{this.bank_id}</Link></td>
                                </tr>
                                <tr>
                                <th scope="row">Account Number:</th>
                                <td>{this.account_no}</td>
                                </tr>
                                <tr>
                                <th scope="row">Bank Name:</th>
                                <td>{this.state.bankName}</td>
                                </tr>
                                <tr>
                                <th scope="row">Bank Short Name:</th>
                                <td>{this.state.shortName}</td>
                                </tr>
                                <tr>
                                <th scope="row">Bank Address:</th>
                                <td>{this.state.bankAddress}</td>
                                </tr>
                                <tr>
                                <th scope="row">BIC:</th>
                                <td>{this.state.bic}</td>
                                </tr>                  
                              </tbody>
                              </table>  
                        </CardBody>
                      </Card>         


              <Card className="card border-primary rounded mt-2" outline color="primary"> 
                <CardHeader className="card-header bg-primary text-white">
                <h2 class="mt-0 mb-2">Comments for IBAN account: </h2>
               <p class="mt-0 mb-0">{this.country_code} {this.notformatted_no_country}</p>
                </CardHeader>
                        <CardBody className="card-body">
                        <div class="text-left">

                                
                                {this.state.comments ? 
                            this.state.comments.map(comment => (
                              <Card className="card border-primary rounded mt-2" outline color="primary"> 
                              <CardHeader className="card-header bg-primary text-white">
                              <h5 class="mt-0">{comment.user} </h5>                              
                              
                              </CardHeader>
                              <CardBody className="card-body">{comment.comment}</CardBody>
                              </Card>     
                              
                                          ))
                            : 
                            "No comments for the account found."
                          }
                 
                        </div>
                        </CardBody>
              </Card>                      
              </Col>
              </Row>              

            </Container>    
            </div>
          );
    }
  }

export default WtbAccountInfo;