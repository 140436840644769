import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Container,
  Form,
  FormGroup,
  FormErrors,
  Label,
  Input,
  Button,
  Row,
  InputGroup,
  FormFeedback,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    InputGroupAddon,
    InputGroupText,
    Jumbotron
} from 'reactstrap';

import {  Redirect } from "react-router-dom";

import { BrowserRouter, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AvForm, AvField } from 'availity-reactstrap-validation';


class WtbJumbo extends React.Component {
    constructor() {
      super();
      //this.handleSubmit = this.handleSubmit.bind(this);
    }  

    render() {
        return ( 
            <Container>                 
                <div>                 
        <Jumbotron fluid className="mt-5 pt-2 pb-3">
          <Container fluid>
              <Row>
        <Col>    <h1 className="display-5 text-center mt-3 mb-5">IBAN</h1>
            <p className="lead text-justify">
            IBAN for Deutsche Bank in Germany consists of 22 characters:
<ul><li>2 letter country code</li>
<li>2 digit check number</li>
<li>8 digits for the Deutsche Bank bank code (BLZ code)</li>
<li>10 digit code for the Deutsche Bank bank account number</li>
</ul>
            </p>
        </Col>
        <Col>    <h1 className="display-5 text-center mt-3 mb-5">Check Digits</h1>
            <p className="lead text-justify">First two digits in the IBAN number (just after country code) are a check digit. This is a form of redundancy check used for error detection on identification numbers, such as bank account numbers, which are used in an application where they will at least sometimes be input manually. It is analogous to a binary parity bit used to check for errors in computer-generated data.</p>
        </Col>
              </Row>
          </Container>
        </Jumbotron>
        </div>             
            </Container>                
          );
    }
  }

export default WtbJumbo;